<template>
  <div class="relative">
    <portal to="topbar-left">
      <div
        @click="$router.go(-1)"
        class="flex w-auto flex-row items-center space-x-3 font-bold"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 22"
          class="h-6 w-6"
        >
          <g
            fill="currentColor"
            fill-rule="evenodd"
            transform="translate(-4 -1)"
          >
            <path
              d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z"
            />
          </g>
        </svg>
      </div>
    </portal>
    <div class="mx-auto flex min-h-full max-w-screen-sm flex-col">
      <div v-if="article" class="relative w-full space-y-4 px-4">
        <h1 class="text-xl font-semibold">{{ $t(article.title) }}</h1>

        <div
          class="relative mx-auto mt-6 min-h-full max-w-screen-sm overflow-hidden break-words rounded-lg bg-white text-sm leading-normal"
        >
          <div class="space-y-4 p-4">
            <div class="prose prose-sm max-w-none break-words">
              <vue-markdown>{{ $t(article.content) }}</vue-markdown>
            </div>

            <div v-if="article.image" class="pt-10">
              <img :src="article.image" class="w-auto max-w-full" />
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        {{ $t("emergency_articles.not_found") }}
      </div>
    </div>
  </div>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
import { mapGetters } from "vuex";
const EmergencyRepository = Repository.get("emergency");
import VueMarkdown from "vue-markdown";

export default {
  name: "info-emergency-article",
  components: {
    VueMarkdown,
  },
  props: {
    id: {
      type: String,
    },
  },
  data: () => ({
    article: null,
  }),
  computed: {
    ...mapGetters(["isCheckedIn"]),
  },
  metaInfo() {
    return {
      title: `${this.article?.title || "Unknown"} - ${this.$i18n.t(
        "pages.emergency"
      )}`,
    };
  },
  mounted() {
    this.article = EmergencyRepository.get(this.id);
  },
};
</script>
